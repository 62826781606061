<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible>
      <div class="logo">
        <h1 style="color: white; text-align: center; margin: 0; padding: 10px;">MyApp</h1>
      </div>
      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
        <a-sub-menu key="sub1">
          <template #title>
            <span>
              <user-outlined style="color: orange;"/>
              <span>用户管理</span>
            </span>
          </template>
          <a-menu-item key="3">账号管理</a-menu-item>
          <a-menu-item key="4">权限管理</a-menu-item>
          <a-menu-item key="5">角色管理</a-menu-item>
        </a-sub-menu>
        <a-menu-item key="1">
          <pie-chart-outlined/>
          <span>Option 1</span>
        </a-menu-item>
        <a-menu-item key="2">
          <desktop-outlined/>
          <span>Option 2</span>
        </a-menu-item>

        <a-sub-menu key="sub2">
          <template #title>
            <span>
              <team-outlined/>
              <span>Team</span>
            </span>
          </template>
          <a-menu-item key="6">Team 1</a-menu-item>
          <a-menu-item key="8">Team 2</a-menu-item>
        </a-sub-menu>
        <a-menu-item key="9">
          <file-outlined/>
          <span>File</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
<!--      <a-layout-header style="background: #fff; padding: 0"/>-->
      <a-layout-content style="margin: 0 16px">
        <router-view ></router-view>
<!--        <a-breadcrumb style="margin: 16px 0">-->
<!--          <a-breadcrumb-item>User</a-breadcrumb-item>-->
<!--          <a-breadcrumb-item>Bill</a-breadcrumb-item>-->
<!--        </a-breadcrumb>-->
<!--        <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">-->
<!--          Bill is a cat.-->
<!--        </div>-->
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        Ant Design ©2018 Created by Ant UED
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script setup>
import {ref} from 'vue';

const collapsed = ref(false);
const selectedKeys = ref(['1']);
</script>
<style scoped>
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>

<style>
.logo {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  max-height: 80%;
  max-width: 90%;
}
</style>