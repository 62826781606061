import { createRouter, createWebHistory } from 'vue-router'
import AdminHomeView   from "@/views/admin/AdminHomeView.vue";

import { isMobile } from '@/utils/utils';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPasswordView.vue')
  },
  // 主应用路由
  {
    path: '/app',
    component: () => import('@/views/app/AppLayout.vue'),
    children: [
      {
        path: 'home',
        name: 'Home',
        // component: () => import('@/views/app/HomeView.vue')
        component: () => import('@/views/app/LocationView.vue')
      },
      {
        path: 'location',
        name: 'AppLocation',
        component: () => import('@/views/app/LocationView.vue')
      },
      {
        path: 'flag',
        name: 'Flag',
        component: () => import('@/views/app/FlagListView.vue')
      },
      {
        path: 'message',
        name: 'AppMessage',
        component: () => import('@/views/app/MessageView.vue')
      },
      {
        path: 'profile',
        name: 'AppProfile',
        component: () => import('@/views/app/ProfileView.vue')
      },
      {
        path: '',
        redirect: 'home'
      }
      // ... 其他应用内路由
    ]
  },
  {
    path: '/gopark-admin',
    component: AdminHomeView,
    // children: [
    //   {
    //     path: '',
    //     redirect: { name: 'location' } // 重定向到默认的子路由
    //   },
    //   {
    //     path: 'location', // 修改为 'location' 以避免与父路径冲突
    //     name: 'location',
    //     component: AdminLocationView
    //   },
    //   // {
    //   //     path: '/',
    //   //     name: 'location',
    //   //     component: AdminLocationView
    //   // },
    // ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由守卫：根据终端类型跳转
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    if (isMobile()) {
      next('/mobile');  // 修改这里，直接跳转到 /mobile 路径
    } else {
      next('/desktop');  // 为了保持一致性，也修改为路径形式
    }
  } else {
    next();
  }
});

export default router
